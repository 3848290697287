
.screen__info {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   -webkit-box-align: start;
   -ms-flex-align: start;
   align-items: start;
   margin-top: 6px;
   margin-bottom: 18px;
}

.screen__information {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -ms-flex-direction: row;
   flex-direction: row;
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   width: 100%;
   margin-bottom: 10px;
}

.screen__data {
   font-weight: 600;
}

.screen__title {
   width: 41%;
}

.screen-button {
   width: 100%;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   background-color: #fbbe07;
   color: #ffffff;
   border: 0;
   border-radius: 4px;
   padding: 12px;
   font-weight: 600;
   cursor: pointer;
}

.screen-button:hover,
.screen-button:focus {
   background-color: #ffd453;
}

.screen-button__icon {
   width: 36px;
   height: 36px;
   margin-right: 20px;
}

.load__text {
   display: block;
   font-size: 18px;
   margin-top: 6px;
   margin-bottom: 18px;
}

.checkbox {
   position: relative;
   display: block;
   margin-top: 12px;
}

.check-input {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   position: absolute;
}

.check-label {
   padding-left: 15px;
   font-size: 14px;
   color: #242729;
   cursor: pointer;
   margin-left: 5px;
}

.check-label:before {
   content: "";
   display: block;
   width: 14px;
   height: 14px;
   border: 1px solid #fbbe07;
   border-radius: 4px;
   background-color: #ffffff;
   position: absolute;
   top: 4px;
   /* расстояние от верха родителя */
   left: 0;
   /* расстояние слева от родителя */
   z-index: 1;
   /* на нижнем слое */
   -webkit-transition: border 0.1s linear;
   transition: border 0.1s linear;
   /* плавный переход для border */
}

.check-label:after {
   content: "";
   display: block;
   width: 6px;
   height: 6px;
   border: solid #ffffff;
   border-width: 0 1px 1px 0;
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
   opacity: 0;
   /* полностью прозрачный */
   position: absolute;
   top: 7px;
   left: 4px;
   z-index: 2;
   /* на верхнем слое */
   -webkit-transition: opacity 0.1s linear;
   transition: opacity 0.1s linear;
   /* плавный переход для opacity */
}

.check-input:checked+.check-label:before {
   background-color: #fbbe07;
}

.check-input:checked+.check-label:after {
   opacity: 1;
}

.input__error-text,
.search__error {
   display: none;
}

.input__error-text--active,
.search__error--active {
   display: block;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   text-align: center;
   color: #eb5757;
   font-size: 11px;
   line-height: 14px;
   letter-spacing: 0.05em;
   text-transform: none;
   white-space: nowrap;
   margin-top: 4px;
   -ms-flex-item-align: center;
   -ms-grid-row-align: center;
   align-self: center;
}

.load__submit {
   margin-top: 20px;
   padding: 20px;
}

.load__submit:disabled {
   background-color: #aeaeae;
   cursor: auto;
}
@media (max-width: 720px) {
   .screen__information {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
   }

   .screen__title {
      width: 100%;
   }

   .screen__data {
      width: 100%;
   }
}

@media (max-width: 640px) {
   
   
   .screen__information {
      font-size: 14px;
   }

   .screen-button {
      padding: 20px;
   }

   .check-label {
      font-size: 13px;
   }

   .load__text {
      font-size: 16px;
   }

}

@media (max-width: 520px) {
   

   .screen__info {
      margin-bottom: 10px;
   }

   .screen__information {
      font-size: 13px;
   }

   .screen-button {
      font-size: 16px;
      padding: 15px;
   }

   .screen-button__icon {
      width: 24px;
      height: 24px;
      margin-right: 20px;
   }

   .load__text {
      font-size: 14px;
      margin-bottom: 10px;
   }

   
}
