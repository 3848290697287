.clinics__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
  margin-top: 20px;
}

.clinics__title {
  font-size: 22px;
  font-weight: 600;
}

.clinics__arrow {
  width: 16px;
  height: 16px;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.clinics__arrow_open {
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.clinics__body {
  display: none;
}

.clinics__body_open {
  display: block;
}

.clinics__group {
  margin-bottom: 16px;
}

.clinics__group-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.clinics__item {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  .container {
    max-width: 600px;
  }
  .clinics__title {
    font-size: 18px;
  }
  .clinics__arrow {
    width: 18px;
    height: 18px;
  }
  .clinics__group-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .clinics__item {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

@media (max-width: 520px) {
  .container {
    max-width: 480px;
  }
  .clinics__title {
    font-size: 16px;
  }
  .clinics__arrow {
    width: 16px;
    height: 16px;
  }
  .clinics__group {
    margin-bottom: 12px;
  }
  .clinics__group-title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .clinics__item {
    font-size: 14px;
    margin-bottom: 6px;
  }
}

@media (max-width: 420px) {
  .container {
    max-width: 380px;
  }
}
/*# sourceMappingURL=clinics.css.map */